@import '../../../../scss/theme-bootstrap';

.site-search-results {
  position: absolute;
  width: 100%;
  top: 100%;
  .typeahead-wrapper {
    background-color: $color-white;
    @include breakpoint($large-up) {
      @include transition(display 0.25s ease-in-out, visibility 0.25s ease-in-out);
      background-color: unset;
    }
  }
  &__close {
    position: absolute;
    top: 15px;
    #{$rdirection}: 10px;
    color: $color-black;
    cursor: pointer;
    @include breakpoint($landscape-up) {
      #{$rdirection}: 30px;
    }
  }
  .product {
    &__image-wrapper {
      width: 23%;
      padding: 1px;
      @include breakpoint($large-up) {
        display: unset;
        width: unset;
      }
    }
    &--typeahead {
      list-style-type: none;
      width: 100%;
      display: flex;
      border: 1px solid $color-light-gray;
      border-bottom: none;
      @include breakpoint($large-up) {
        border: 1px solid $color-black;
        display: block;
      }
      @include breakpoint($landscape-up) {
        display: inline-block;
        float: $ldirection;
        width: 19%;
        border: none;
        padding: 16px 5px;
        @include breakpoint($large-up) {
          padding-top: 40px;
        }
      }
      &:last-child {
        border-bottom: 1px solid $color-light-gray;
        @include breakpoint($large-up) {
          border-bottom: unset;
        }
      }
    }
    &__abstract {
      width: 77%;
      display: inline-block;
      margin: 0;
      position: relative;
      min-height: 60px;
      padding: 10px 30px 0;
      @include breakpoint($large-up) {
        text-align: $ldirection;
        height: unset;
        max-width: 250px;
        margin: unset;
        position: unset;
        display: block;
        width: unset;
        padding: unset;
      }
    }
    &__headline {
      font-weight: bold;
      a {
        text-decoration: none;
      }
    }
    &__description {
      font-size: 11px;
      width: 100%;
      overflow: hidden;
      text-align: $ldirection;
      text-overflow: ellipsis;
      display: -webkit-box;
      max-height: 32px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @include breakpoint($large-up) {
        display: block;
        overflow: unset;
        text-overflow: unset;
        max-height: unset;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
      }
    }
    &__rating {
      display: none;
    }
    &-results {
      background: $color-white;
      color: $color-black;
      padding-top: 42px;
      @include breakpoint($large-up) {
        display: flex;
        justify-content: center;
        padding-top: unset;
      }
    }
    &__image {
      display: inline-block;
      @include breakpoint($large-up) {
        width: 306px;
        float: $ldirection;
        display: block;
      }
    }
    &__price {
      display: none;
    }
  }
  .icon-search {
    color: $color-gray;
    margin-top: 8px;
    position: absolute;
    #{$ldirection}: 7.5%;
    @include breakpoint($large-up) {
      #{$ldirection}: 6.5%;
    }
  }
  .search-term.field {
    height: 28px;
    width: 88%;
    border-radius: 0;
    padding: 14px 34px;
    margin: 0 6%;
    &.search-result--show {
      display: block;
    }
  }
  .see-results {
    background: $color-black;
    height: 32px;
    position: absolute;
    text-align: center;
    display: none;
    top: 0;
    width: 80%;
    padding-top: 0;
    #{$ldirection}: 50%;
    transform: translateX(-50%);
    margin: 5px auto;
    border: none;
    @include breakpoint($large-up) {
      margin: 0 auto;
      position: unset;
      padding-top: unset;
      top: unset;
      width: unset;
      #{$ldirection}: 0;
      transform: none;
      border: 2px solid $color-white;
    }
    li {
      padding: 0;
      @include breakpoint($large-up) {
        @include swap_direction(padding, 3px 57px 0 0);
      }
    }
  }
  .search-suggestions {
    &__item {
      list-style-type: none;
    }
    &__link {
      line-height: 32px;
      color: $color-white;
      @include breakpoint($large-up) {
        line-height: inherit;
      }
    }
  }
  .form-type-textfield {
    width: 100%;
    background: $color-black;
    border: 1px solid $color-white;
    margin-top: 1px;
    padding: 21px 0;
    @include breakpoint($landscape-up) {
      display: inline-flex;
      justify-content: center;
      margin-top: unset;
    }
  }
  .icon-arrow_left {
    color: $color-white;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}
